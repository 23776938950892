import React, {
  ComponentType,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { formatNumber, getPosition } from '../../util';
import './counter.scss';

interface Props {
  icon: 'globe' | 'sleep' | 'airplane';
  num: number;
  label: string;
  increment?: number;
}
export const Counter: ComponentType<Props> = (props) => {
  const [currentNum, setCurrentNum] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const { num, icon, label, increment = 10 } = props;
  const interval = useRef<any>();
  const incNum = useRef<number>(0);
  const ref = useRef<any>();

  const inc = () => {
    if (incNum.current >= 0) {
      incNum.current += increment;
      setCurrentNum(incNum.current);

      if (incNum.current >= num) {
        setCurrentNum(num);
        clearInterval(interval.current);
      }
    }
  };

  const onScroll = useCallback(() => {
    setScrollTop(window.scrollY);
  }, []);

  useEffect(() => {
    if (
      !initialized &&
      scrollTop > getPosition(ref.current).y - window.innerHeight
    ) {
      interval.current = setInterval(inc, 50);
      setInitialized(true);
    }
  }, [initialized, scrollTop]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div ref={ref} className="counter">
      <div className="counter-top">
        <img src={`/images/icon-${icon}.png`} />
        <div className="number">{formatNumber(currentNum)}</div>
      </div>
      <div className="counter-bottom">{label}</div>
    </div>
  );
};
