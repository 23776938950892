import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import imageAmondo from '../assets/images/amondo-logo.png';
import { Counter } from '../components/counter/counter';
import { Header } from '../components/header';
import { Hero } from '../components/hero';
import { Logo } from '../components/logo';
import { Navigation } from '../components/navigation/navigation';
import { Ornament } from '../components/ornament/ornament';
import { Page } from '../components/page';
import { Slider } from '../components/slider/slider';
import { TextPane } from '../components/text-pane/text-pane';

export default () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Page
      header={
        <Header siteTitle={data.site.siteMetadata?.title || `Title`}>
          <Navigation isHome />
          <Hero />
        </Header>
      }
    >
      <div className="section">
        <Ornament image={'lake-top'} side="right" size="20vw" top="40vh" />

        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-8">
              <h2>Es zieht euch in die fernen Länder dieser Welt?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-md-8">
              <TextPane>
                <p>
                  Ihr träumt von einem Roadtrip durch Kalifornien, von Strand
                  und Wassersport auf den Kanaren oder einer Tour mit dem
                  Campervan durch Kroatien?
                </p>
              </TextPane>
            </div>
          </div>
          <Ornament
            image={'boat'}
            relative
            size="60px"
            offsetLeft="72%"
            top="0px"
          />
          <div className="row">
            <div className="col col-12 col-md-8">
              <TextPane>
                <p>
                  Aber bei dem Gedanken an die Reiseplanung, die aufwendige
                  Recherche nach Hotelbewertungen und Flugverbindungen sowie dem
                  anschließenden Buchungsprozess braucht ihr jetzt schon Urlaub?
                </p>
              </TextPane>
            </div>
          </div>
          <Ornament
            image={'airplane'}
            relative
            size="60px"
            offsetLeft="4vw"
            top="0px"
          />
          <div className="row">
            <div className="col col-4 d-none d-md-block"></div>
            <div className="col col-12 col-md-8">
              <h2>Dann seid ihr bei mir genau richtig!</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-4 d-none d-md-block"></div>
            <div className="col col-12 col-md-8">
              <TextPane>
                <p>
                  Ich kümmere mich um eure Reise während ihr euch schon
                  entspannt zurücklehnen und von eurem Abenteuer träumen könnt!
                  Schreibt mir einfach eure Reisewünsche an{' '}
                  <a href="mailto:info@vivelevoyage.de">info@vivelevoyage.de</a>{' '}
                  und erhaltet euer individuelles Angebot ganz unverbindlich und
                  kostenlos.
                </p>
              </TextPane>
            </div>
          </div>
        </div>
      </div>
      <Slider
        slides={[
          { image: 'slider-1-a.jpg' },
          { image: 'slider-1-b.jpg' },
          { image: 'slider-1-c.jpg' },
          { image: 'slider-1-d.jpg' },
        ]}
      />
      <div className="section" id="mein-angebot">
        <Ornament image={'street'} side="left" size="100%" top="10%" />
        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-8">
              <h2>Ich biete dir</h2>
            </div>
          </div>
          <div className="row">
            <div className="col d-none d-md-block col-2 col-lg-4"></div>
            <div className="col col-12 col-md-10 col-lg-8">
              <div className="row">
                <div className="col col-12 col-md-4 with-pane">
                  <div className="blue pane">
                    Kostenfreie und vollkommen unverbindliche Reiseberatung bis
                    hin zur Buchung
                  </div>
                </div>
                <div className="col col-12 col-md-4 with-pane">
                  <div className="blue pane">
                    Individuelle und authentische Reiseplanung für dein ganz
                    besonderes Abenteuer
                  </div>
                </div>
                <div className="col col-12 col-md-4 with-pane">
                  <div className="blue pane">
                    Meine Tipps aus erster Hand zu Destinationen weltweit
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-12 col-md-4 d-none d-md-block"></div>
                <div className="col col-12 col-md-4 with-pane">
                  <div className="blue pane">
                    Umfangreiches Portfolio an Reiseleistungen jeglicher Art
                  </div>
                </div>
                <div className="col col-12 col-md-4 with-pane">
                  <div className="blue pane">
                    Übernahme der kompletten Formalitäten auch nach dem
                    Buchungs&shy;prozess
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-12 col-md-4 d-none d-md-block"></div>
                <div className="col col-12 col-md-4 d-none d-md-block"></div>
                <div className="col col-12 col-md-4 with-pane">
                  <div className="blue pane">
                    Persönliche Betreuung bei Unregelmäßig&shy;keiten durch mich
                    und das AMONDO-Team
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ornament
          image={'jeep'}
          relative
          size="60px"
          offsetLeft="40%"
          top="0px"
        />
      </div>

      <div className="section bg-dark-blue about-me" id="ueber-mich">
        <div className="container">
          <h2>Hey, ich bin Oliver!</h2>
        </div>
        <div className="image-wrapper right">
          <img src="/images/about-me.jpg" />
        </div>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col col-12 col-xs-4 col-sm-3">
              <Counter
                icon="airplane"
                num={782925}
                label="Flugmeilen"
                increment={9114}
              />
            </div>
            <div className="col col-12 col-xs-4 col-sm-3">
              <Counter
                icon="sleep"
                num={1621}
                label="Übernachtungen"
                increment={19}
              />
            </div>
            <div className="col col-12 col-xs-4 col-sm-3">
              <Counter icon="globe" num={73} label="Länder" increment={1} />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col col-12 col-md-6">
              <p>
                <strong>
                  Geboren, aufgewachsen und wohnhaft in Köln, arbeite ich nun
                  seit zehn Jahren in der bunten Welt der Reisebranche.
                </strong>
              </p>
              <p>
                Durch meine vielen Geschäftsreisen und Urlaube habe ich die
                Möglichkeit viele unterschiedliche Orte und Regionen unseres
                wundervollen Planeten zu bereisen.
              </p>
              <p>
                Mit großer Leidenschaft und Vorfreude beginne ich schon Monate
                vor Abreise mit der akribischen Planung, um das beste aus meinem
                Trip herauszuholen. Ich liebe es vorab ausgiebig über das
                jeweilige Ziel zu lesen, mich durch die verschiedenen
                Aktivitäten und Points of Interest zu forsten, um dann das für
                mich optimale Abenteuer zusammenzustellen.
              </p>
            </div>
            <div className="col col-12 col-md-6">
              <p>
                Zunehmend habe ich angefangen auch für Familie und Freunde
                entsprechende Reisen zu organisieren, bevor dann die Idee kam,
                diese Leidenschaft mit Hilfe der Reiseagentur AMONDO
                professionell auszuweiten.
              </p>
              <p>
                Hierbei begeisterte ich mich schon immer für alle Farben und
                Formen des Reisens: Roadtrips durch die USA, Safaritouren in
                Südafrika, Heimaturlaub in Deutschland, Karibikkreuzfahrten,
                Backpacking in Australien, Campingtrips, Pauschal- und
                Cluburlaube sowie vieles mehr. Jegliche Form des Reisens
                erweitert meiner Meinung nach den persönlichen Horizont, stärkt
                das Bewusstsein für die Vielfalt unserer Welt und ist auf ihre
                Weise von ganz exklusivem Reiz.
              </p>
            </div>
          </div>
        </div>
        <Slider
          className="mt-5 mb-5 horizontal"
          slides={[
            { image: 'slider-2-a.jpg' },
            { image: 'slider-2-b.jpg' },
            { image: 'slider-2-c.jpg' },
            { image: 'slider-2-d.jpg' },
            { image: 'slider-2-e.jpg' },
          ]}
        />
        <Ornament
          image={'compass'}
          relative
          size="60px"
          offsetLeft="70%"
          top="30px"
        />
      </div>
      <div className="section" id="kundenstimmen">
        <Ornament image={'lake-bottom'} side="left" size="20%" top="60%" />

        <div className="container text-center">
          <Logo variant />
        </div>
        <div className="container">
          <h2>
            Das sagen
            <br />
            meine Kunden über mich
          </h2>
        </div>

        <Slider
          slides={[
            {
              image: 'slider-3-a.jpg',
              content: (
                <>
                  <p>
                    Urlaub sollte immer Entspannung bedeuten. Um auf Nummer
                    sicher zu gehen haben wir uns deshalb, nach einigen
                    Empfehlungen aus dem Bekanntenkreis, an Oliver gewandt und
                    ihn mit unserer Reiseplanung beauftragt.
                  </p>
                  <p>
                    Er hat uns das rundum sorglos Paket organisiert und wir
                    mussten uns um nichts mehr kümmern. Auch auf unsere
                    Extrawünsche ist er verständnisvoll eingegangen und hat
                    diese zu unserer vollsten Zufriedenheit erfüllt.
                  </p>
                  <p>
                    Das Hotel, das Oliver für uns ausgewählt hat, war einsame
                    Spitze. Er hat ein wirklich gutes Gespür für die
                    individuellen Bedürfnisse seiner Kunden und scheut keine
                    Mühen, dass der Urlaub zu einem unvergesslichen Erlebnis
                    wird.
                  </p>
                  <p>
                    Wir können Oliver deshalb mit vollster Überzeugung weiter
                    empfehlen und freuen uns schon auf den nächsten Trip den er
                    für uns organisiert.
                  </p>
                  <p>
                    <strong>HUGO</strong>
                    <br />
                    in Südafrika
                  </p>
                </>
              ),
            },
            {
              image: 'slider-3-b.jpg',
              content: (
                <>
                  <p>
                    Dieses Jahr war ich das erste Mal auf Kreta. Da ich meinen
                    Urlaub nicht gerne selbst plane, habe ich mich an Oliver
                    gewandt.
                  </p>
                  <p>
                    Durch unser persönliches Gespräch und seine individuelle
                    Beratung konnte er mir einen, für mich perfekten, Urlaub
                    organisieren.
                  </p>
                  <p>
                    Dabei hat Oliver nicht nur den Standard (An-/Abreise,
                    Unterkünfte etc.) bedient, sondern ist insbesondere auch auf
                    meine persönlichen Wünsche in puncto Freizeitgestaltung und
                    Ausflugsmöglichkeiten eingegangen. Ich bin immer noch total
                    begeistert! Ein abwechslungsreicher Urlaub voller Abenteuer
                    und Entspannung.
                  </p>
                  <p>
                    <strong>ROBIN</strong>
                    <br />
                    auf Kreta
                  </p>
                </>
              ),
            },
            {
              image: 'slider-3-c.jpg',
              content: (
                <>
                  <p>
                    Im Norden wilde Natur erleben, Tempel im Inland erkunden,
                    Surfen an der Ostküste: Von meiner Reise durch Sri Lanka
                    hatte ich ganz spezielle Vorstellungen. Oliver hat sich viel
                    Zeit genommen, sich meine Wünsche anzuhören und mir
                    daraufhin die perfekte Route zusammengestellt.
                  </p>
                  <p>
                    Der Backpack-Trip abseits der Touristenpfade war traumhaft
                    schön und der optimale Mix aus Reise, Abenteuer und
                    Entspannung. Auch während meines Trips war Oliver immer
                    ansprechbar und stand für alle Rückfragen zur Verfügung.
                    Danke Vive le Voyage für die tolle Organisation und die
                    vielen einzigartigen Insider-Tipps!
                  </p>
                  <p>
                    <strong>ELINA</strong>
                    <br />
                    in Sri Lanka
                  </p>
                </>
              ),
            },
            {
              image: 'slider-3-d.jpg',
              content: (
                <>
                  <p>
                    Oliver hat uns nicht nur einen perfekten Urlaub geplant,
                    sondern Momente geschaffen, die im Herzen bleiben. Es ist
                    unvergleichbar, wie Oliver auf die persönlichen
                    Vorstellungen eingeht und sie mit eigenen Ideen erweitert.
                  </p>
                  <p>
                    Der perfekte Urlaub sieht für jeden anders aus - aber Oliver
                    schafft es daraus DEIN Erlebnis zu machen! Vielen Dank!
                  </p>
                  <p>
                    <strong>MELLI &amp; NIKLAS</strong>
                    <br />
                    auf Bali
                  </p>
                </>
              ),
            },
            {
              image: 'slider-3-e.jpg',
              content: (
                <>
                  <p>
                    Wir haben Oliver für die Planung unserer Reise nach
                    Fuerteventura kontaktiert, da er die Insel sehr gut kennt
                    und selbst schon viele Male dort gewesen ist. 
                  </p>
                  <p>
                    Er hat uns unzählige Tipps für Ausflüge auf der Insel
                    gegeben und wir konnten dadurch auch einige nicht
                    touristische Spots erkunden. Gerade die Geheimtipps haben
                    die Reise zu einem unvergesslichen Erlebnis gemacht.
                  </p>
                  <p>
                    Besonders herausstreichen möchten wir den exzellenten
                    Service, den Oliver uns geboten hat, da wir uns wirklich um
                    nichts kümmern mussten. Aufgrund dieser Erfahrungen können
                    wir Oliver jedem, der einen stressfreien Urlaub genießen
                    möchte, empfehlen. 
                  </p>
                  <p>
                    <strong>DOMINIK</strong>
                    <br />
                    auf Fuerteventura
                  </p>
                </>
              ),
            },
            {
              image: 'slider-3-f.jpg',
              content: (
                <>
                  <p>
                    Oliver hatte die perfekten Traveltipps für unser
                    Inselhopping auf den Balearen parat.
                  </p>
                  <p>
                    Die Beratung war einfach super unkompliziert und herzlich.
                    Außerdem konnten wir noch während der Reise einen der vielen
                    Stopps ganz easy mit Olivers Hilfe ändern.
                  </p>
                  <p>
                    Die Unterkünfte waren alle wie zugeschnitten auf meinen
                    Freund und mich. Somit konnten wir uns dank Vive le Voyage
                    voll und ganz auf unsere Erholung konzentrieren!
                  </p>
                  <p>
                    <strong>MAGGY</strong>
                    <br />
                    auf Ibiza
                  </p>
                </>
              ),
            },
          ]}
        />
        <Ornament
          image={'sailing'}
          relative
          size="60px"
          offsetLeft="30px"
          top="10%"
        />
      </div>
      <div className="section" id="my-roadtrip">
        <Ornament image={'street-2'} side="left" size="80%" top="0%" />
        <div className="container">
          <h2>
            Deine ganz <br /> persönliche Rundreise
            <br /> - myRoadtrip
          </h2>
        </div>
        <div className="image-wrapper right">
          <img src="/images/my-roadtrip-1.jpg" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-6">
              <p>
                Der Reiz des Roadtrips liegt für mich zum einen in der bunten
                Vielfalt aus Stadt, Natur und Erholung, sowie zum anderen in der
                Freiheit meine Tagesabläufe selbst zu gestalten und in jeglicher
                Hinsicht unabhängig zu sein.
              </p>
              <p>
                Außerdem komme ich so den Menschen, länderspezifischen
                Eigenheiten und der jeweiligen Kultur am nächsten und kann
                dementsprechend auf diese Art besonders wertvolle Eindrücke und
                Erfahrungen sammeln.
              </p>
            </div>
            <div className="col col-12 col-md-6">
              <p>
                Frei von allen Verpflichtungen auf den Straßen dieser Welt
                unterwegs sein. Ist das so einfach möglich? Wenn ihr gute
                Vorarbeit leistet, kann dieser Reisetraum tatsächlich
                Wirklichkeit werden. Grundsätzlich gilt: Je mehr ihr vor Antritt
                eures Roadtrips organisiert habt, desto mehr könnt ihr euren
                Urlaub genießen und müsst euch weniger Gedanken über logistische
                und planerische Herausforderungen machen.
              </p>
            </div>
          </div>
        </div>
        <div className="image-wrapper left">
          <img src="/images/my-roadtrip-2.jpg" />
        </div>
        <Ornament
          image={'camper'}
          relative
          size="60px"
          offsetLeft="70%"
          top="10%"
        />
        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-6">
              <p>
                Dazu fehlt euch die Zeit? Ihr seid euch unsicher, wo ihr
                anfangen sollt oder habt einfach keine Lust euch durch die
                abertausenden Angebote von Hotels, Mietwagen und Flügen zu
                wühlen?
              </p>
              <p>
                Dann schreibt mich gerne an und ich stelle euch euren
                maßgeschneiderten Roadtrip zusammen.
              </p>
              <p>
                Gemeinsam besprechen wir wohin es gehen soll, wie viele Stops
                ihr machen wollt, welche Autoklasse es sein soll und vieles
                mehr. Nach ein paar Tagen erhaltet ihr dann ein ausführliches
                Angebot mit verschiedenen Optionen, welches ich Schritt für
                Schritt mit euch durchgehen werde. Danach können wir gemeinsam
                entweder weitere Anpassungen vornehmen oder eure Reise final
                einbuchen.
              </p>
            </div>
            <div className="col col-12 col-md-6">
              <p>
                Rechtzeitig vor Abreise erhaltet ihr dann von mir euer
                persönliches Travelbook mit wertvollen Tipps zu Restaurants,
                Unternehmungen etc. sowie alle eure Reisevoucher.
              </p>
              <p>Worauf wartet ihr noch?</p>
              <p>
                Euer Roadtrip ist schon zum greifen nah. Zögert nicht lange und
                schreibt mir eine Mail.
              </p>
              <p>
                <a href="mailto:info@vivelevoyage.de">info@vivelevoyage.de</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container text-center mt-5">
          <img className="half-width" src={imageAmondo} />
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col col-12 col-md-6">
              <p>
                Durch meine Kooperation mit dem Reiseveranstalter AMONDO habe
                ich Zugriff auf alle gängigen Tourismussoftwares und somit die
                Möglichkeit dir deine Reisen aus dem vollumfänglichen Angebot
                aller Hotels, Fluggesellschaften, Mietwagenfirmen und
                Reiseveranstaltern auf dem Markt zusammenzustellen.
              </p>
              <p>
                Ich fungiere hierbei lediglich als Reisevermittler, weshalb ich
                an keine speziellen Leistungsträger gebunden bin und dir dein
                persönliches Abenteuer aus den attraktivsten Angeboten zu den
                besten Preisen zusammenstellen kann.
              </p>
              <p>
                Dabei bleibt der komplette Beratungsprozess für dich kostenfrei
                und unverbindlich.
              </p>
            </div>
            <div className="col col-12 col-md-6">
              <p>
                <strong>
                  Meine Beratungen, Angebote und Buchungen umfassen unter
                  anderem:
                </strong>
              </p>
              <ul>
                <li>- Flüge</li>
                <li>- Hotels</li>
                <li>- Kreuzfahrten</li>
                <li>- Campingtrips</li>
                <li>- Ferienwohnungen</li>
                <li>- Pauschalreisen</li>
                <li>- Wochenendtrips</li>
                <li>- Luxusreisen</li>
                <li>- Städtereisen</li>
                <li>- Cluburlaub</li>
                <li>
                  - Kombinierte Reisen (z.B. eine Woche Inselhopping, eine Woche
                  Strandurlaub)
                </li>
                <li>- uvm.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
