import classNames from 'classnames';
import React, { ComponentType } from 'react';
import './ornament.scss';

interface OrnamentProps {
  image?: string;
  side?: 'left' | 'right' | 'full';
  size?: string;
  top: string;
  relative?: boolean;
  offsetLeft?: string;
}
export const Ornament: ComponentType<OrnamentProps> = (props) => {
  const { side = 'left', offsetLeft, image, size, top, relative } = props;
  return (
    <div
      className={classNames('ornament', side, { relative })}
      style={{ maxWidth: size, maxHeight: size, top, left: offsetLeft }}
    >
      <img src={`/images/ornament-${image}.png`} />
    </div>
  );
};
