import React, { ComponentType } from 'react';
import { Logo } from './logo';
interface Props {}
export const Hero: ComponentType<Props> = ({}) => {
  return (
    <div className="hero-slider">
      <div
        className="slide"
        style={{ backgroundImage: 'url(images/header-1.png)' }}
      >
        <Logo />
      </div>
    </div>
  );
};
