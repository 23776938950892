import classNames from 'classnames';
import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Carousel } from 'react-responsive-carousel';
import './slider.scss';

interface Props {
  slides: { image: string; content?: ReactNode }[];
  className?: string;
}

export const Slider: ComponentType<Props> = (props) => {
  const [current, setCurrent] = useState(0);
  const { slides, className } = props;
  const onChange = useCallback((slide) => {
    setCurrent(slide);
  }, []);
  const hasDescriptions = useMemo(() => {
    return slides.filter((s) => s.content).length > 0;
  }, [slides]);
  return (
    <>
      <Carousel
        centerMode={false}
        className={classNames('slider', className)}
        showThumbs={false}
        showStatus={false}
        showArrows={true}
        showIndicators={true}
        swipeable
        emulateTouch
        infiniteLoop={true}
        interval={4000}
        swipeScrollTolerance={10}
        onChange={onChange}
        autoPlay={hasDescriptions === false}
      >
        {slides.map((slide) => (
          <img src={`/images/${slide.image}`} />
        ))}
      </Carousel>
      {hasDescriptions && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-12 col-md-6">
              {slides.map((slide, index) => (
                <div
                  className={`slide-text slide-${index} ${
                    index === current ? 'current' : ''
                  }`}
                >
                  {slide.content}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
